import { createReducer } from '@reduxjs/toolkit';

import {
  deleteCurrentUserFailure,
  deleteCurrentUserStart,
  deleteCurrentUserSuccess,
  getCurrentUserFailure,
  getCurrentUserNotificationsFailure,
  getCurrentUserNotificationsStart,
  getCurrentUserNotificationsSuccess,
  getCurrentUserStart,
  getCurrentUserSuccess,
  getProfileRecommendationsFailure,
  getProfileRecommendationsStart,
  getProfileRecommendationsSuccess,
  getProfileSupportSubjectsFailure,
  getProfileSupportSubjectsStart,
  getProfileSupportSubjectsSuccess,
  patchCurrentUserFailure,
  patchCurrentUserNotificationsFailure,
  patchCurrentUserNotificationsStart,
  patchCurrentUserNotificationsSuccess,
  patchCurrentUserStart,
  patchCurrentUserSubscriptionsFailure,
  patchCurrentUserSubscriptionsStart,
  patchCurrentUserSubscriptionsSuccess,
  patchCurrentUserSuccess,
  postCloseProfileRecommendationsFailure,
  postCloseProfileRecommendationsStart,
  postCloseProfileRecommendationsSuccess,
  postProfileSupportRequestFailure,
  postProfileSupportRequestStart,
  postProfileSupportRequestSuccess, resetCurrentUserDeleteError, resetCurrentUserError,
  resetCurrentUserNotificationsError,
  resetCurrentUserSubscriptionsError,
  updateCurrentUser,
} from 'core/redux/actions/profileActions';
import { optimisticallyUpdateCurrentUserData } from 'utils/redux/profileReducerUtilsRedux';

import { ReduxRequestStatus } from 'core/types/redux/commonTypesRedux';
import { ProfileState } from 'core/types/redux/profileTypesRedux';

const initialState: ProfileState = {
  currentUser: {
    data: null,
    status: ReduxRequestStatus.init,
    error: null,
    deleteData: {
      status: ReduxRequestStatus.init,
      error: null,
    },
    notificationsStatuses: {
      notificationsGetStatus: ReduxRequestStatus.init,
      notificationsGetError: null,
      notificationsPatchStatuses: {}
    },
    subscriptionsStatuses: {},
    recommendations: {
      data: null,
      status: ReduxRequestStatus.init,
      error: null,
      closeRecommendations: {
        status: ReduxRequestStatus.init,
        error: null,
      }
    },
  },
  supportRequest: {
    subjects: {
      data: null,
      status: ReduxRequestStatus.init,
      error: null,
    },
    data: null,
    status: ReduxRequestStatus.init,
    error: null,
  },
};

export const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCurrentUserStart, (state) => {
      state.currentUser.status = ReduxRequestStatus.initLoading;
    })
    .addCase(getCurrentUserSuccess, (state, action) => {
      state.currentUser.status = ReduxRequestStatus.success;
      state.currentUser.data = {
        ...state.currentUser.data,
        ...action.payload,
        contactInfo: {
          ...action.payload.contactInfo,
          phoneNotificationsOptIn: null,
          emailNotificationsOptIn: null,
          secondaryEmailNotificationsOptIn: null,
        },
        subscriptions: [],
      }
    })
    .addCase(getCurrentUserFailure, (state, action) => {
      state.currentUser.status = ReduxRequestStatus.initError;
      state.currentUser.error = action.payload;
    })
    .addCase(resetCurrentUserError, (state) => {
      state.currentUser.status = ReduxRequestStatus.success;
      state.currentUser.error = null;
    })
    .addCase(patchCurrentUserStart, (state) => {
      state.currentUser.status = ReduxRequestStatus.loading;
      state.currentUser.error = null;
    })
    .addCase(patchCurrentUserSuccess, (state, action) => {
      state.currentUser.status = ReduxRequestStatus.success;
      state.currentUser.data = optimisticallyUpdateCurrentUserData(state.currentUser.data, action.payload);
    })
    .addCase(patchCurrentUserFailure, (state, action) => {
      state.currentUser.status = ReduxRequestStatus.error;
      state.currentUser.error = action.payload;
    })
    .addCase(updateCurrentUser, (state, action) => {
      if (state.currentUser.data) {
        state.currentUser.data = {
          ...state.currentUser.data,
          ...action.payload,
          contactInfo: {
            ...state.currentUser.data.contactInfo,
            ...action.payload.contactInfo,
          }
        }
      }
    })
    .addCase(getCurrentUserNotificationsStart, (state) => {
      state.currentUser.notificationsStatuses.notificationsGetStatus = ReduxRequestStatus.initLoading
    })
    .addCase(getCurrentUserNotificationsSuccess, (state, action) => {
      state.currentUser.notificationsStatuses.notificationsGetStatus = ReduxRequestStatus.success;
      const { phoneNotificationsOptIn, emailNotificationsOptIn, secondaryEmailNotificationsOptIn, subscriptions } = action.payload;
      
      if(state.currentUser.data) {
        state.currentUser.data.contactInfo = {
          ...state.currentUser.data.contactInfo,
          phoneNotificationsOptIn,
          emailNotificationsOptIn,
          secondaryEmailNotificationsOptIn,
        }
        state.currentUser.data.subscriptions = subscriptions;
      }
    })
    .addCase(getCurrentUserNotificationsFailure, (state, action) => {
      state.currentUser.notificationsStatuses.notificationsGetStatus = ReduxRequestStatus.error;
      state.currentUser.notificationsStatuses.notificationsGetError = action.payload;
    })
    .addCase(patchCurrentUserNotificationsStart, (state, action) => {
      const { payload } = action;

      state.currentUser.notificationsStatuses.notificationsPatchStatuses[payload] = { status: ReduxRequestStatus.loading, error: null };
    })
    .addCase(patchCurrentUserNotificationsSuccess, (state, action) => {
      const {
        payload: {
          data: { phoneNotificationsOptIn, emailNotificationsOptIn, secondaryEmailNotificationsOptIn, subscriptions },
          name,
        }
      } = action;

      state.currentUser.notificationsStatuses.notificationsPatchStatuses[name] = { status: ReduxRequestStatus.success, error: null };
      
      if(state.currentUser.data) {
        state.currentUser.data.contactInfo = {
          ...state.currentUser.data.contactInfo,
          phoneNotificationsOptIn,
          emailNotificationsOptIn,
          secondaryEmailNotificationsOptIn,
        }
        state.currentUser.data.subscriptions = subscriptions;
      }
    })
    .addCase(patchCurrentUserNotificationsFailure, (state, action) => {
      const { payload: { error, name } } = action;
      
      state.currentUser.notificationsStatuses.notificationsPatchStatuses[name] = { status: ReduxRequestStatus.error, error };
    })
    .addCase(resetCurrentUserNotificationsError, (state, action) => {
      const { payload } = action;
      
      state.currentUser.notificationsStatuses.notificationsPatchStatuses[payload] = { status: ReduxRequestStatus.init, error: null };
    })
    .addCase(patchCurrentUserSubscriptionsStart, (state, action) => {
      const { payload } = action;
      
      state.currentUser.subscriptionsStatuses[payload] = { status: ReduxRequestStatus.loading, error: null };
    })
    .addCase(patchCurrentUserSubscriptionsSuccess, (state, action) => {
      const {
        payload: {
          data: { phoneNotificationsOptIn, emailNotificationsOptIn, secondaryEmailNotificationsOptIn, subscriptions },
          name,
        }
      } = action;
      
      state.currentUser.subscriptionsStatuses[name] = { status: ReduxRequestStatus.success, error: null };

      if(state.currentUser.data) {
        state.currentUser.data.contactInfo = {
          ...state.currentUser.data.contactInfo,
          phoneNotificationsOptIn,
          emailNotificationsOptIn,
          secondaryEmailNotificationsOptIn,
        }
        state.currentUser.data.subscriptions = subscriptions;
      }
    })
    .addCase(patchCurrentUserSubscriptionsFailure, (state, action) => {
      const { payload: { error, name } } = action;
      
      state.currentUser.subscriptionsStatuses[name] = { status: ReduxRequestStatus.error, error };
    })
    .addCase(resetCurrentUserSubscriptionsError, (state, action) => {
      const { payload } = action;
      
      state.currentUser.subscriptionsStatuses[payload] = { status: ReduxRequestStatus.init, error: null };
    })
    .addCase(deleteCurrentUserStart, (state) => {
      state.currentUser.deleteData.status = ReduxRequestStatus.loading;
      state.currentUser.deleteData.error = null;
    })
    .addCase(deleteCurrentUserSuccess, (state) => {
      state.currentUser.deleteData.status = ReduxRequestStatus.success;
    })
    .addCase(deleteCurrentUserFailure, (state, action) => {
      state.currentUser.deleteData.status = ReduxRequestStatus.error;
      state.currentUser.deleteData.error = action.payload;
    })
    .addCase(resetCurrentUserDeleteError, (state) => {
      state.currentUser.deleteData.status = ReduxRequestStatus.init;
      state.currentUser.deleteData.error = null;
    })
    .addCase(getProfileSupportSubjectsStart, (state) => {
      state.supportRequest.subjects.status = ReduxRequestStatus.loading;
    })
    .addCase(getProfileSupportSubjectsSuccess, (state, action) => {
      state.supportRequest.subjects.status = ReduxRequestStatus.success;
      state.supportRequest.subjects.data = action.payload;
    })
    .addCase(getProfileSupportSubjectsFailure, (state, action) => {
      state.supportRequest.subjects.status = ReduxRequestStatus.error;
      state.supportRequest.subjects.error = action.payload;
    })
    .addCase(postProfileSupportRequestStart, (state) => {
      state.supportRequest.status = ReduxRequestStatus.loading;
      state.supportRequest.error = null;
    })
    .addCase(postProfileSupportRequestSuccess, (state, action) => {
      state.supportRequest.status = ReduxRequestStatus.success;
      state.supportRequest.data = action.payload;
    })
    .addCase(postProfileSupportRequestFailure, (state, action) => {
      state.supportRequest.status = ReduxRequestStatus.error;
      state.supportRequest.error = action.payload;
    })
    .addCase(getProfileRecommendationsStart, (state) => {
      state.currentUser.recommendations.status = ReduxRequestStatus.loading;
      state.currentUser.recommendations.error = null;
    })
    .addCase(getProfileRecommendationsSuccess, (state, action) => {
      state.currentUser.recommendations.status = ReduxRequestStatus.success;
      state.currentUser.recommendations.data = action.payload;
    })
    .addCase(getProfileRecommendationsFailure, (state, action) => {
      state.currentUser.recommendations.status = ReduxRequestStatus.error;
      state.currentUser.recommendations.error = action.payload;
    })
    .addCase(postCloseProfileRecommendationsStart, (state) => {
      state.currentUser.recommendations.closeRecommendations.status = ReduxRequestStatus.loading;
      state.currentUser.recommendations.closeRecommendations.error = null;
    })
    .addCase(postCloseProfileRecommendationsSuccess, (state, action) => {
      state.currentUser.recommendations.closeRecommendations.status = ReduxRequestStatus.success;
      if (state.currentUser.recommendations.data) {
        state.currentUser.recommendations.data.newRecommendations = state.currentUser.recommendations.data?.newRecommendations.map(
          rec => rec.id === action.payload.id
            ? { ...rec, ...action.payload }
            : rec
        );
      }
    })
    .addCase(postCloseProfileRecommendationsFailure, (state, action) => {
      state.currentUser.recommendations.closeRecommendations.status = ReduxRequestStatus.error;
      state.currentUser.recommendations.closeRecommendations.error = action.payload;
    })
});